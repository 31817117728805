<!-- 
Home page section
Data files: 
home-page.json - content of Home page included URL to Contact section
-->
<template>
  <section class="home">
    <div class="intro">
    <svg
      class="intro-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 80 80"
      height="200"
      width="200"
    >
      <path
        class="logo-wings"
        id="l-wing"
        d="M 2.0898573,54.251709 20.949449,16.848852 39.55589,54.315104"
      />
      <path
        class="logo-ends"
        d="M 31.298396,78.099875 39.55589,61.723273 47.702546,78.127632"
      />
      <path
        class="logo-wings"
        d="M 39.55589,54.251709 58.415482,16.848852 77.021923,54.315104"
      />
      <path
        class="logo-body"
        d="M 20.696298,63.8949 39.55589,26.492043 58.162331,63.958295"
      />
      <path
        class="logo-ends"
        d="M 31.298396,18.365136 39.55589,1.9885343 47.702546,18.392893"
      />
    </svg>
    </div>
    <div class="content">
      <p class="home-title">{{ homePage.title }}</p>
      <p class="greeting">{{ homePage.greeting }}</p>
      <p class="home-text">{{ homePage.text }}</p>
      <a class="button" v-bind:href="homePage.url">{{homePage.url_title}}</a>
    </div>
    <div v-if="error" class="card text-error">
      {{ error }}
    </div>
  </section>
</template>
<script>
//import { ApiGet } from "@/components/common/ApiAx.js";
import home_page from "@/components/data/home-page.json";

export default {
  data() {
    return {
      homePage: {},
      error: null,
    };
  },
  
  mounted () {
    //console.log(home_page);
    this.homePage = home_page.data;
  },
  /*
  async mounted() {
    await ApiGet(`${this.apiUrl}/api/home-page`)
      .then((data) => {
        //console.log(data);
        this.homePage = data.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
};
</script>
<style>
.home {
  margin-top: calc(20vh);
  min-height: 100vh;
}
.intro {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.intro-logo {
  fill: none;
  stroke: var(--bg-color);
  stroke-width: 3.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-opacity: 1;
  animation: ch-size calc(var(--anim-const) * 1.5s);
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 7s);  
}
.logo-body {
  animation: ch-stroke calc(var(--anim-const) * 4.5s) ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 0.5s);      
}
.logo-ends {
  animation: ch-stroke-error calc(var(--anim-const) * 4.5s) ease-in-out; 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 1.5s);      
}
.logo-wings {
  animation: ch-stroke-success calc(var(--anim-const) * 4.5s) ease-in-out; 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 3s);      
}
@keyframes ch-stroke {
  0% {stroke: var(--bg-color);}
  /*60% {stroke: #002040;}*/
  60% {stroke: #505070;}
  100%{stroke: var(--color-darkGrey);}
}
@keyframes ch-stroke-error {
  0% {stroke: var(--bg-color);}
  30% {stroke: #300a0a;}
  60% {stroke: #505070;}
  100%{stroke: var(--color-darkGrey);}
}
@keyframes ch-stroke-success {
  0% {stroke: var(--bg-color);}
  30% {stroke: #0f300f;}
  60% {stroke: #505070;}
  100%{stroke: var(--color-darkGrey);}
}
@keyframes ch-size {
  100%{width: 0; height: 0;}
}
.home-title {
  color: var(--bg-color);
  font-size: 5em;
  margin: 0;
  animation: ch-color calc(var(--anim-const) * 2s); 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 9s);
}
@keyframes ch-color {
    0% {color: var(--bg-color); }
  100%{color: var(--color-primary); }
}
.home-text {
  /*color: var(--text-grey);*/
  color: var(--bg-color);
  font-size: 1.5em;
  padding-top: 2rem;
  animation: ch-color-grey calc(var(--anim-const) * 2s) ease-out; 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 13s);
}
.greeting {
  /*color: var(--text-grey);*/
  color: var(--bg-color);
  font-size: 2em;
  margin: 0;
  animation: ch-color-grey calc(var(--anim-const) * 2s) ease-out; 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 11s);
}
@keyframes ch-color-grey {
    0% {color: var(--bg-color); }
  100%{color: var(--text-grey); }
}
.home .content .button {
  background-color: transparent;
  color: var(--bg-color);
  border-color: var(--bg-color);
  animation: ch-color-success calc(var(--anim-const) * 2s) ease-out; 
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 15s);

}
@keyframes ch-color-success {
  0% {color: var(--bg-color); border-color: var(--bg-color);}
  100%{color: var(--color-success); border-color: var(--color-success);}
}
/*@keyframes content-show {
  99% {visibility: hidden;}
  100% {visibility: visible;}
}*/

/* Scale side padding of main content */
@media screen and (min-width: 900px) {
  .intro-logo {
    width: 300px;
    height: 300px;
  }
}
@media screen and (min-width: 1200px) {
  .intro-logo {
    width: 400px;
    height: 400px;
  }
}
</style>