<!--
List of skills
Data files: 
  skill-page.json - title and general text
  Skill.json - list of skills grouped by types
  Skill-type - list of skill types
-->
<template>
  <section id="skills">
    <div v-if="error" class="card text-error">
      {{ error }}
    </div>
    <div v-else>
      <h1 class="sec-title">{{ skillPage.title }}</h1>
      <VueShowdown :markdown="skillPage.content" />
      <div class="row">
        <div
          class="col-6 col-4-md card bg-grey"
          v-for="type in skillTypes"
          :key="type"
        >
          <div class="row header">{{type.type}}</div>
          <div
            class="skill-row"
            v-for="skill in filterSkillsByType(type.type)"
            :key="skill.id"
          >
            <div class="skill">{{ skill.name }}</div>
            <div class="level">
              <span
                class="tag is-small text-white"
                :class="levelColor(skill.level)"
                >{{ skill.level }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { ApiGet } from "@/components/common/ApiAx.js";
import skill_page from "@/components/data/skill-page.json";
import skills from "@/components/data/skill.json";
import skill_type from "@/components/data/skill-type.json";

export default {
  data() {
    return {
      skillPage: {},
      skillItems: [],
      skillTypes: [],
      error: null,
    };
  },
  mounted () {
    this.skillPage = skill_page.data;
    this.skillItems = skills.data;
    this.skillTypes = skill_type.data;
    
  },
  /*async mounted() {
    await ApiGet(`${this.apiUrl}/api/skill-page`)
      .then((data) => {
        //console.log(data);
        this.skillPage = data.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
    let query = {
      populate: '*',
    };  
    await ApiGet(`${this.apiUrl}/api/skills`, query)
      .then((data) => {
        //console.log(data);
        this.skills = data.data;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
    await ApiGet(`${this.apiUrl}/api/skill-types`)
      .then((data) => {
        //console.log(data);
        this.types = data.data;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
  methods: {
    /**
     * filterSkillsByType - filter by skill type and ordered and colored according skill level
     * 
     * @param {string} skill type
     */
    filterSkillsByType(type) {
      return this.skillItems
        .filter((skill) => skill.skill_type.data.type == type)
        .sort((a, b) => b.level - a.level);
    },
    levelColor(level) {
      if (level > 60) {
        return "bg-success ";
      } else if (level <= 30) {
        return "bg-error";
      } else {
        return "bg-dark";
      }
    },
  },
};
</script>
<style>
#skills {
  padding-top: 4em;
  min-height: 50vh;
}
.header {
  content: attr(data-header);
  font-size: 1.4em;
  font-weight: bold;
  padding-left: 0.3em;
}
.skill-row {
  display: grid;
  grid-template-columns: 1fr fit-content(8rem);
}
.skill {
  font-size: 0.9em;
  padding-left: 0.5em;
}

.level {
  font-size: 0.8em;
}
@media screen and (min-width: 600px){
  #skills {
    padding-top: 10rem;
    min-height: 100vh;
  }  
}

@media screen and (min-width: 900px){
  #skills {
    padding-top: 14rem;
    min-height: 150vh;
  }  
}

</style>