import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/css/chota.min.css' //simple css framework
import '@/assets/css/main.css'
import { VueShowdownPlugin } from 'vue-showdown'; //markdown resolver
const app = createApp(App)

// Glogal variable for Strapi server address
app.config.globalProperties.apiUrl = 'https://prochstprofile.herokuapp.com.test'
app.config.globalProperties.mediaPath = '/media/'
app.config.globalProperties.iconPath = require("@/assets/img/remixicon.symbol.svg")
app.use(VueShowdownPlugin)
app.mount('#app')
