<!-- 
Contact information
Data files: 
contact-page.json - title, content and email url
-->
<template>
  <section id="contact">
    <div v-if="error" class="card text-error">
      {{ error }}
    </div>
    <div v-else>
      <h1 class="sec-title">{{ contactPage.title }}</h1>
      <VueShowdown :markdown="contactPage.content" />
      <a
        class="button outline success"
        v-bind:href="`${contactPage.email_url}`"
        :title="contactPage.email_title" target="_blank"
        >{{contactPage.email_title}}</a
      >
    </div>
  </section>
</template>
<script>
/*import { ApiGet } from "@/components/common/ApiAx.js";*/
import contact_page from "@/components/data/contact-page.json";

export default{
  data() {
    return {
      contactPage: {},
      error: null,
    };
  },
  mounted () {
    //console.log(contact_page);
    this.contactPage = contact_page.data;
  },
  /*
  async mounted() {
    await ApiGet(`${this.apiUrl}/api/contact-page`)
      .then((data) => {
        //console.log(data);
        this.contactPage = data.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
}
</script>
<style>
#contact {
  padding-top: 5rem;
  min-height: 80vh;
}
@media screen and (min-width: 600px){
  #contact {
    padding-top: 12rem;
    min-height: 90vh;
  }  
}

@media screen and (min-width: 900px){
  #contact {
    padding-top: 17rem;
  }  
}

</style>