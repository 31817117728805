<!--
List of references to recent projects
Data files: 
  work-page.json - title and general text
  Skill.json - list of works - title, content, skill, URL, image
-->
<template>
  <section id="work">
    <div v-if="error" class="card text-error">
      {{ error }}
    </div>
    <div v-else>
      <h1 class="sec-title">{{ workPage.title }}</h1>
      <VueShowdown :markdown="workPage.content" />
      <div class="work" v-for="work in workItems" :key="work.id">
        <div :class="['thumb', isOdd(work.id) ? 'thumb-odd' : 'thumb-even']">
          <a v-bind:href="work.url" target="_blank">
            <img
              class="thumb-img"
              :src="this.mediaPath + work.thumbnail.data.name"
            />
          </a>  
        </div>
        <div :class="['title', isOdd(work.id) ? 'title-odd' : 'title-even']">
          {{ work.title }}
        </div>
        <VueShowdown
          :markdown="work.description"
          :class="['desc', isOdd(work.id) ? 'desc-odd' : 'desc-even']"
        />
        <div :class="['skills', isOdd(work.id) ? 'skills-odd' : 'skills-even']">
          {{ work.skills }}
        </div>
        <div :class="['url-ref', isOdd(work.id) ? 'url-ref-odd' : 'url-ref-even']">
          <a
            v-bind:href="work.url"
            v-bind:title="work.title"
            target="_blank"
          >
            <svg class="icon">
              <use
                :xlink:href="`${this.iconPath}#ri-${work.icon}-fill`"
              ></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import { ApiGet } from "@/components/common/ApiAx.js";
import work_page from "@/components/data/work-page.json";
import works from "@/components/data/work.json";

export default {
  data() {
    return {
      workPage: {},
      workItems: [],
      error: null,
      // iconPath: require("@/assets/img/remixicon.symbol.svg"),
    };
  },
  mounted () {
    this.workPage = work_page.data;
    this.workItems = works.data;
  },
  /*async mounted() {
    await ApiGet(`${this.apiUrl}/api/work-page`)
      .then((data) => {
        //console.log(data);
        this.workPage = data.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
    let query = {
      populate: "*",
    };
    await ApiGet(`${this.apiUrl}/api/works`, query)
      .then((data) => {
        //console.log(data);
        this.works = data.data;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
  methods: {
    isOdd(num) {
      //console.log(num%2 == 0);
      return num % 2 != 0;
    },
  },
};
</script>
<style>
#work {
  padding-top: 5rem;
  min-height: 50vh;
}
.work {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 5rem;
}
.thumb {
  grid-column: 1 / 6;
  grid-row: 1;
  text-align: left;
  background-color: var(--bg-color);
  opacity: 0.8;
  z-index: 1;
}
.thumb:hover  {
  opacity: 1;
  z-index: 10;
}
.title {
  font-size: 1.6em;
  padding-top: 1rem;
  background-color: rgba(0, 0, 40, 0.7);
  z-index: 5;
}
.title-odd {
  grid-column:  1 / 6;
  grid-row: 2;
  text-align: left;
}
.title-even {
  grid-column:  1 / 6;
  grid-row: 2;
  text-align: left;
}
.desc {
  background-color: rgba(0, 0, 40, 0.7);
  /*background-color: rgba(0, 16, 80, 0.6);*/
  z-index: 5;
}
.desc-odd {
  grid-column:  1 / 6;
  grid-row: 3;
  text-align: left;
}
.desc-even {
  grid-column:  1 / 6;
  grid-row: 3;
  text-align: left;
}
.skills {
  font-size: smaller;
  font-weight: bolder;
  font-family: monospace;
  color: var(--color-error);
  background-color: transparent;
  z-index: 5;
}
.skills-odd {
  grid-column:  1 / 6;
  grid-row: 4;
  text-align: right;
}
.skills-even {
  grid-column:  1 / 6;
  grid-row: 4;
  text-align: right;
}
.url-ref {
  background-color: transparent;
  z-index: 5;
}
.url-ref-odd {
  grid-column:  1 / 6;
  grid-row: 5;
  text-align: right;
}
.url-ref-even {
  grid-column:  1 / 6;
  grid-row: 5;
  text-align: right;
}
.icon {
  width: 1.5em;
  height: 1.5em;
  fill: var(--color-primary);
}
@media screen and (min-width: 600px){
  #work {
    padding-top: 10rem;
    min-height: 200vh;
  }  
}

@media screen and (min-width: 780px) {
  #work {
    padding-top: 15rem;
    min-height: 250vh;
  }  
  .thumb {
    opacity: 0.5;
  }
  .thumb-img {
  max-height: 300px;
  }
  .thumb-odd {
    grid-column: 1 / 4;
    grid-row: 1 / span 5;
  }
  .thumb-even {
    grid-column: 3 / 6;
    grid-row: 1 / span 5;
    text-align: right;
  }
  .title {
    font-size: 1.8em;
  }
  .title-odd {
    grid-column: 2 / 6;
    text-align: right;  
  }
  .title-even {
    grid-column: 1 / 5;
  }
  .desc {
    min-height: 12rem;
  }
  .desc-odd {
    grid-column: 2 / 6;
    text-align: right;
  }
  .desc-even {
    grid-column: 1 / 5;
  }
  .skills-odd {
    grid-column: 2 / 6;
  }
  .skills-even {
    grid-column: 1 / 5;
    text-align: left;
  }
  .url-ref-even {
    text-align: left;
  }
}
</style>