<!-- 
Fixed top menu 
Data files:
menu.json - title, URL path, icon, type
            data is filtered for type 'main'
-->
<template>
  <div class="is-fixed">
    <input class="nav-btn" type="checkbox" id="nav-btn" />
    <label class="nav-lbl" for="nav-btn"><span class="nav-icon"></span></label>    
    <nav class="nav">
      <div class="tabs nav-right">
        <a
          v-for="menu in this.mainMenus"
          :key="menu.id"
          v-bind:href="menu.url"
          :title="menu.title"
          @click="navBtnClick"
        >
          {{ menu.title }}
        </a>
      </div>
    </nav>
  </div>
</template>
<script>
// import { ApiGet } from "@/components/common/ApiAx.js";
import menus from "@/components/data/menu.json";

export default {
  data() {
    return {
      mainMenus: [],
    };
  },
  methods: {
    navBtnClick() {
      document.getElementById("nav-btn").checked = false;
    },
  },
  mounted() {
    //console.log(menus);
    this.mainMenus = menus.data.filter(item => item.type === 'main');
  },
  /*async mounted() {
    let query = {
      filters: {
        type: {
          $eq: "main",
        },
      },
    };
    await ApiGet(`${this.apiUrl}/api/menus`, query)
      .then((data) => {
        //console.log(data);
        this.mainMenus = data.data;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
};
</script>
<style>
.is-fixed {
  padding-right: 10rem;
  /*background-color: var(--bg-color);*/
  z-index: 100;
}
.nav {
  background-color: var(--bg-color);
  top: 0;
  max-height: 0;
  min-height: 0 !important;
  transition: max-height .4s ease-out; 
  visibility: hidden;
  animation: calc(var(--anim-const) * 17s) nav-show;
  animation-fill-mode: forwards;
}
@keyframes nav-show {
  99% { visibility: hidden;}
  100% {visibility: visible;}
}
.tabs {
  color: var(--color-darkGrey);
  flex-direction: column;
}

.nav a {
  justify-content: center;
  border-left: 2px solid var(--color-lightGrey);
  border-right: 2px solid var(--color-lightGrey);  
}
.nav-btn {
  display: none;
}
.nav-lbl {
  background-color: var(--bg-color);
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}
.nav-lbl .nav-icon {
  background: var(--color-darkGrey);
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}
.nav-lbl .nav-icon:hover,
.nav-lbl .nav-icon:active
{
  background-color: var(--color-lightGrey);
}
.nav-lbl .nav-icon:before,
.nav-lbl .nav-icon:after {
  background: var(--color-darkGrey);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .4s ease-out;
  width: 100%;
}
.nav-lbl .nav-icon:hover::before,
.nav-lbl .nav-icon:hover::after,
.nav-lbl .nav-icon:active::before,
.nav-lbl .nav-icon:active::after {
  background-color: var(--color-lightGrey);
}
.nav-lbl .nav-icon:before {
  top: 5px;
}
.nav-lbl .nav-icon:after {
  top: -5px;
}
.nav-btn:checked ~ .nav {
  max-height: 20rem;
  background-color: var(--bg-color);
  opacity: 0.85;
}
.nav-btn:checked ~ .nav-lbl .nav-icon {
  background: transparent;
}
.nav-btn:checked ~ .nav-lbl .nav-icon:before {
  transform: rotate(-45deg);
}

.nav-btn:checked ~ .nav-lbl .nav-icon:after {
  transform: rotate(45deg);
}
.nav-btn:checked ~ .nav-lbl .nav-icon:before,
.nav-btn:checked ~ .nav-lbl .nav-icon:after {
  top: 0;
}

@media (min-width: 600px) {
  section {
  padding-top: 10rem;
  }
  .nav {
    min-height: 5rem !important;
  }
  .nav a {
  border-left: none;
  border-right: none;     
  }
  .tabs {
    flex-direction: row; 
  }
  .nav-lbl {
    display: none;
  }  
}
</style>