<!-- 
Aside fixed section with brand logo side menu with social links
Data files: 
menu.json - title, URL path, icon, type
            data is filtered for type 'social'
-->
<template>
  <aside class="sidebar">
    <div class="top">
      <a class="brand-logo" href="#" title="Home">
        <svg class="logo" viewBox="0 0 80 80">
          <use :xlink:href="`${logoPath}#path1467`"></use>
        </svg>
      </a>
    </div>
    <div class="foot">
      <ul>
        <li v-for="menu in this.socialMenus" :key="menu.id">
          <a target="_blank" v-bind:href="menu.url" v-bind:title="menu.title">
            <svg class="social-icon">
              <use :xlink:href="`${this.iconPath}#ri-${menu.icon}-line`"></use>
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>
<script>
/*import { ApiGet } from "@/components/common/ApiAx.js";*/
import menus from "@/components/data/menu.json";

export default {
  data() {
    return {
      socialMenus: [],
      logo: {},
      logoPath: require("@/assets/img/BSLogo.svg"),
      error: null,
    };
  },
  mounted() {
    //console.log(menus);
    this.socialMenus = menus.data.filter(item => item.type === 'social');
    //console.log(this.socialMenus);
  },
  /*async mounted() {
    let query = {
      filters: {
        type: {
          $eq: "social",
        },
      },
    };
    await ApiGet(`${this.apiUrl}/api/menus`, query)
      .then((data) => {
        //console.log(data);
        this.socialMenus = data.data;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
    query = {
      populate: "*",
    };
    await ApiGet(`${this.apiUrl}/api/home-page`, query)
      .then((data) => {
        //console.log(data);
        this.logo = data.data.attributes.logo.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
};
</script>
<style>
.sidebar {
  overflow: visible;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100vh;
  text-align: center;
  padding: 0.5rem 1.5rem;
  /*visibility: hidden;
  animation: calc(var(--anim-const) * 12.5s) sidebar-show;
  animation-fill-mode: forwards;*/
}

/*@keyframes sidebar-show {
  99% { visibility: hidden;}
  100% {visibility: visible;}
}*/
.sidebar .top {
  margin-top: 1rem;
}

.sidebar .foot {
  margin-top: calc(100vh - 25rem);
}

.sidebar .foot ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.brand-logo {
  width: 5em;
  ;
  height: 5em;
}

.logo {
  width: 3em;
  height: 3em;
  fill: none;
  stroke: var(--bg-color);
  animation: ch-stroke-darkGrey calc(var(--anim-const) * 4s) ease-out;
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 7.3s);

}

@keyframes ch-stroke-darkGrey {
  0% {
    stroke: var(--bg-color);
  }

  100% {
    stroke: var(--color-darkGrey);
  }
}

.social-icon {
  width: 1.5em;
  height: 1.5em;
  fill: var(--bg-color);
  animation: ch-fill-darkGrey calc(var(--anim-const) * 4s) ease-out;
  animation-fill-mode: forwards;
  animation-delay: calc(var(--anim-const) * 15s);
}

@keyframes ch-fill-darkGrey {
  0% {
    fill: var(--bg-color);
  }

  100% {
    fill: var(--color-darkGrey);
  }
}
</style>