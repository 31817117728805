<!-- 
Brief information about the myself
Data file:
    about-page - title and content, URL profile document
-->
<template>
  <section id="about">
    <div v-if="error" class="card text-error">
      {{ error }}
    </div>
    <div v-else>
      <h1 class="sec-title">{{ aboutPage.title }}</h1>
      <VueShowdown :markdown="aboutPage.content" />
      <a
        class="button outline success"
        v-bind:href="this.mediaPath + profile.name"
        title="Download my resume" target="_blank"
        >Profile</a
      >
    </div>
  </section>
</template>
<script>
// import { ApiGet } from "@/components/common/ApiAx.js";
import about_page from "@/components/data/about-page.json";

export default {
  name: "App",
  data() {
    return {
      aboutPage: {},
      profile: {},
      error: null,
    };
  },
  mounted () {
    //console.log(about_page);
    this.aboutPage = about_page.data;
    this.profile = about_page.data.profile.data;
  },
  /*async mounted() {
    let query = {
      populate: "*",
    };
    await ApiGet(`${this.apiUrl}/api/about-page`, query)
      .then((data) => {
        //console.log(data);
        this.aboutPage = data.data.attributes;
        this.profile = data.data.attributes.profile.data.attributes;
      })
      .catch((error) => {
        //console.error(error);
        this.error = error;
      });
  },*/
};
</script>
<style>
#about {
  padding-top: 7rem;
  min-height: 50vh;
}
#about h2 {
  color: var(--color-primary);
}
@media screen and (min-width: 600px){
  #about {
    padding-top: 14rem;
    min-height: 100vh;
  }  
}

@media screen and (min-width: 900px){
  #about {
    padding-top: 17rem;
    min-height: 150vh;
  }  
}
</style>